<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              v-if="hasPermission('create other contracts')"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({name: 'researcher.administration.other-contracts.create', query: { actAs: $route.query.actAs }}).href"
              target="_blank"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'other contracts'"
              :total="dataTotal"
              :fetch-path="'otherContracts/fetch'"
              :filter-path="'otherContracts/filter'"
              :export-path="'otherContracts/export'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        target="_blank"
                        :href="$router.resolve({ name: 'researcher.dashboard', params: { id: field.user.id }, query: { actAs: $route.query.actAs } }).href"
                      >
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <div
                        v-if="hasPermission('update other contracts')"
                        class="dropdown-divider"
                      />
                      <b-dropdown-item
                        v-if="hasPermission('update other contracts')"
                        :href="$router.resolve({name: 'researcher.administration.other-contracts.edit', params: { id: field.id }, query: { actAs: $route.query.actAs }}).href"
                        target="_blank"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="hasPermission('delete other contracts')"
                        @click="showDeleteModal(field.id)"
                      >
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="hasAdminPermission('view other contracts')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    v-model="filters.researcher"
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Status</label>
                  <v-select
                    v-model="filters.status"
                    label="status"
                    :options="statuses"
                    :get-option-key="option => option.id"
                    @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.status : ''}`, value: $event }, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Host</label>
                  <v-select
                    v-model="filters.host"
                    label="name"
                    :clearable="true"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                    @input="saveFilter({ customLabel: true, name: `Host: ${$event ? $event.name : ''}`, value: $event }, 'host')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Institution of the contract</label>
                  <v-select
                    v-model="filters.institution"
                    label="name"
                    :clearable="true"
                    :options="hosts"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/filter')"
                    @input="saveFilter({ customLabel: true, name: `Institution of the contract: ${$event ? $event.name : ''}`, value: $event }, 'institution')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >University</label>
                  <select
                    v-model="filters.university"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'University: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'university')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <p><strong>Begin date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters.begin_date_from"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Begin date from: ${$event}`, value: $event }, 'begin_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters.begin_date_to"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Begin date to: ${$event}`, value: $event }, 'begin_date_to')"
                  />
                </div>
                <p><strong>End date</strong></p>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters.end_date_from"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `End date from: ${$event}`, value: $event }, 'end_date_from')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters.end_date_to"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `End date to: ${$event}`, value: $event }, 'end_date_to')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Active</label>
                  <select
                    v-model="filters.active"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Active: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'active')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <p><strong>Plurioccupation</strong></p>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Has Plurioccupation</label>
                  <select
                    v-model="filters.pluri"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Has Plurioccupation: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'pluri')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters.end_date_from_pluri"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Plurioccupation from: ${$event}`, value: $event }, 'end_date_from_pluri')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters.end_date_to_pluri"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Plurioccupation to: ${$event}`, value: $event }, 'end_date_to_pluri')"
                  />
                </div>
                <p><strong>Compatibility</strong></p>
                <div class="mb-1">
                  <label
                    for="areaseniorcall"
                    class="form-label"
                  >Has Compatibility</label>
                  <select
                    v-model="filters.compati"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Has compatibility: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'compati')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >From</label>
                  <date-picker
                    v-model="filters.end_date_from_compati"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Compatibility From: ${$event}`, value: $event }, 'end_date_from_compati')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >To</label>
                  <date-picker
                    v-model="filters.end_date_to_compati"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter({ customLabel: true, name: `Compatibility to: ${$event}`, value: $event }, 'end_date_to_compati')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Vue from 'vue'
import CompleteTable from '../../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Type of contract',
          checked: true,
          order: 2,
        },
        {
          name: 'Contract with:',
          checked: true,
          order: 3,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 4,
        },
        {
          name: 'End date',
          checked: true,
          order: 5,
        },
        {
          name: 'Status',
          checked: true,
          order: 6,
        },
        {
          name: 'Pluri',
          checked: true,
          order: 7,
        },
        {
          name: 'Compatibility',
          checked: true,
          order: 8,
        },
        {
          name: 'Active',
          checked: true,
          order: 9,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
        'Type of contract': 'type_table',
        'Contract with:': 'institution_table',
        'Begin date': 'begin_date',
        'End date': 'end_date_table',
        Status: 'status.status_table',
        Pluri: 'plurioccupation_date',
        Compatibility: 'compatibility_date',
        Active: 'active',
      },
      title: 'Other contracts',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'otherContracts/items',
      dataTotal: 'otherContracts/itemsTotal',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      hosts: 'institutions/institutions',
      statuses: 'status/statuses',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'other-contracts')
    await this.$store.dispatch('otherContracts/filter', this.filters)
    this.$store.dispatch('status/filterStatus', 'Other contracts')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'other-contracts',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'otherContracts', url: `${Vue.prototype.$groupUrl}/other-contracts/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
